import React, { Component } from 'react';
export default class ContactUs extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <section id="contact">


        <div className="row section-head">
        </div>
        <div className="row contact-container">

          <div className="seven columns">
            <h2>Contact Details</h2>

            <p className="lead">
              Feel free to contact me for any work or suggestions.
            </p>
            <span>{resumeData.name}</span>
            <br></br>
            <span>
              {resumeData.address}
            </span>
            <br></br>
            <a href={`mailto:${resumeData.email}?subject=Mail from Our Site`}>{resumeData.email}</a>
            <br></br>
            <a href={`tel:${resumeData.phoneNumber}`}>{resumeData.phoneNumber}</a>


          </div>
          <div style={{paddingLeft:'30px'}}> 
            <div class="badge-base LI-profile-badge" data-locale="en_US" data-size="medium" data-theme="dark" data-type="VERTICAL" data-vanity="jalal-heidari" data-version="v1"><a class="badge-base__link LI-simple-link" href="https://ir.linkedin.com/in/jalal-heidari?trk=profile-badge">Jalal Heidari</a></div>
          </div>

        </div>
        {/* <div className="row">
            <aside className="eigth columns footer-widgets">
              <div className="widget">
                <h4>Linked in :
                  {resumeData.linkedinId}
                </h4>
              </div>
            </aside>
          </div> */}
      </section>
    );
  }
}