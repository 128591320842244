let resumeData = {
  "imagebaseurl": "https://rbhatia46.github.io/",
  "name": "Jalal Heidari",
  "role": "Senior Software Engineer",
  // "linkedinId": "jalal-heidari",
  "skypeid": "live:98d5b09121a9cdc7",
  "roleDescription": "",
  "socialLinks": [
    {
      "name": "linkedin",
      "url": "https://www.linkedin.com/in/jalal-heidari/",
      "className": "fa fa-linkedin"
    },
    {
      "name": "stackoverflow",
      "url": "https://stackoverflow.com/users/5998549/jhkcia",
      "className": "fa fa-stack-overflow"
    },
    {
      "name": "github",
      "url": "https://github.com/jhkcia",
      "className": "fa fa-github"
    },
    {
      "name": "skype",
      "url": "https://join.skype.com/invite/cAfwrc4g6zFD",
      "className": "fa fa-skype"
    }
  ],
  "aboutme": "Experienced software engineer with 6 years of experience in all stages of SDLC, with hands-on experience in Java, React, and CI/CD of web applications. My background in Software Engineering and Artificial Intelligence enables me to seek innovative solutions to complex problems and build software systems that are highly scalable, flexible, and maintainable.",
  "address": "Tehran, Iran",
  "phoneNumber": "989120498762",
  "email": "heidari.jalal74@gmail.com",
  "website": "https://jheidari.nl",
  "education": [
    {
      "UniversityName": "Sharif University of Technology",
      "specialization": "Master's in Artificial Intelligence(Bioinformatics)",
      "MonthOfPassing": "09/2019 - ",
      "YearOfPassing": "01/2022",
      "Achievements": ""
    },
    {
      "UniversityName": "Sharif University of Technology",
      "specialization": "Bachelor's in Software Engineering ",
      "MonthOfPassing": "09/2013 - ",
      "YearOfPassing": "09/2018",
      "Achievements": ""
    },
    {
      "UniversityName": "Sampad (National Organization Talented Students)",
      "specialization": "Diploma in Mathematics and Physics ",
      "MonthOfPassing": "09/2011 - ",
      "YearOfPassing": "09/2012",
      "Achievements": ""
    }

  ],
  "work": [
    {
      "CompanyName": "Rebound Returns",
      "specialization": "Senior Full Stack Developer",
      "MonthOfLeaving": "09/2022 - ",
      "YearOfLeaving": "Present",
      "Achievements": "Rebound is the leading global returns management specialist, enabling easy omnichannel returns for retailers and consumers. By combining integrated returns software with an extensive logistics network, ReBound provides brands with a complete ecosystem of suppliers and partners to handle their global, end-to-end returns. ReBound optimizes and manages the entire returns lifecycle so clients can focus on their core business."
    },    {
      "CompanyName": "Onyx Lab",
      "specialization": "Senior Full Stack Developer",
      "MonthOfLeaving": "11/2020 - ",
      "YearOfLeaving": "03/2022",
      "Achievements": "Onyx is a Software Company that works on the development of hardware and software in the field of genetics and pathology."
    },
    {
      "CompanyName": "System Group",
      "specialization": "Full Stack Developer",
      "MonthOfLeaving": "02/2018 - ",
      "YearOfLeaving": "10/2020",
      "Achievements": "System Group is the most prominent IT company in Iran, with more than 50% of the ERP market share."
    },
    {
      "CompanyName": "Viratech Sharif Company",
      "specialization": "Technical Team Lead and Back End Developer",
      "MonthOfLeaving": "03/2016 - ",
      "YearOfLeaving": "10/2018",
      "Achievements": "Viratech is a mid-size company backed by a group of scholars aiming for entrepreneurship and producing quality products in the IT industry."
    }

  ],
  "skillsDescription": "Your skills here",
  "skills": {
    "type":"rounded", //bar | rounded
    "items":[
    {
      "key": "Backend",
      "items": [{
        "name": "Java",
        "experience": 40
      },
      {
        "name": "Springboot",
        "experience": 70
      },
      {
        "name": "Hibernate",
        "experience": 40
      },
      {
        "name": "Maven",
        "experience": 40
      },
      {
        "name": "Gradle",
        "experience": 40
      },
      {
        "name": "C#",
        "experience": 40
      },
      {
        "name": ".Net",
        "experience": 40
      },
      {
        "name": ".Net core",
        "experience": 40
      },
      {
        "name": "Azure DevOps",
        "experience": 40
      },
      {
        "name": "Python",
        "experience": 40
      },
      {
        "name": "Php",
        "experience": 40
      },
      {
        "name": "Laravel",
        "experience": 40
      }
      ]
    },
    {
      "key": "Frontend",
      "items": [{
        "name": "React",
        "experience": 20
      },
      {
        "name": "VueJs",
        "experience": 20
      },
      {
        "name": "Typescript",
        "experience": 20
      },
      {
        "name": "Javascript",
        "experience": 20
      },
      {
        "name": "Webpack",
        "experience": 20
      },
      {
        "name": "Redux",
        "experience": 20
      },
      {
        "name": "hooks",
        "experience": 20
      },
      {
        "name": "Html",
        "experience": 20
      },
      {
        "name": "CSS",
        "experience": 20
      },
      {
        "name": "Sass",
        "experience": 20
      },
      {
        "name": "Less",
        "experience": 20
      },
      {
        "name": "MaterialUI",
        "experience": 20
      },
      {
        "name": "Cypress",
        "experience": 20
      },
      {
        "name": "Jest",
        "experience": 20
      },
      {
        "name": "Mocha",
        "experience": 20
      },
      {
        "name": "Babel",
        "experience": 20
      }
    ]
    },
    {
      "key": "Databases",
      "items": [{
        "name": "PostgreSQL",
        "experience": 40
      },
      {
        "name": "MySQL",
        "experience": 70
      },
      {
        "name": "MS SQL Server",
        "experience": 70
      },
      {
        "name": "Redis",
        "experience": 70
      },
      {
        "name": "ElasticSearch",
        "experience": 70
      },
      {
        "name": "MongoDB",
        "experience": 70
      }]
    },
    {
      "key": "Other",
      "items": [{
        "name": "Linux",
        "experience": 40
      },
      {
        "name": "Docker",
        "experience": 70
      },
      {
        "name": "Kubernetes",
        "experience": 70
      },
      {
        "name": "DDD",
        "experience": 70
      },
      {
        "name": "SOA",
        "experience": 70
      },
      {
        "name": "MVC",
        "experience": 70
      },
      {
        "name": "TDD",
        "experience": 70
      },
      {
        "name": "SOLID",
        "experience": 70
      },
      {
        "name": "OOP",
        "experience": 70
      },
      {
        "name": "Design patterns",
        "experience": 70
      },
      {
        "name": "Git",
        "experience": 70
      },
      {
        "name": "Scrum",
        "experience": 70
      },
      {
        "name": "DevOps",
        "experience": 70
      },
      {
        "name": "Jira",
        "experience": 70
      },
      {
        "name": "Jmeter",
        "experience": 70
      },
      {
        "name": "SonarQube",
        "experience": 70
      },
      {
        "name": "Celery",
        "experience": 70
      },
      {
        "name": "RabbitMQ",
        "experience": 70
      },
      {
        "name": "Nginx",
        "experience": 40
      }]
    },
    {
      "key": "SoftSkills",
      "items": [{
        "name": "Teamwork",
        "experience": 40
      },
      {
        "name": "Fast learning",
        "experience": 70
      },
      {
        "name": "Problem Solving",
        "experience": 70
      },
      {
        "name": "Decision-Making",
        "experience": 70
      },
      ]
    }
  ]},
  "portfolio": [
    {
      "name": "Digilaam",
      "link":"http://digilaam.ir/",
      "description": "Remote pathology or telepathology is a workspace that is easily accessible through normal browsers and provides images created from a pathology slide prepared in a laboratory center to specialists and people who are not in the laboratory.",
      "imgurl": "images/portfolio/digilaam.jpg"
    },
    {
      "name": "Bazaar",
      "link":"https://bazaar.hamkaransystem.ir/shop",
      "description": "Bazaar is a platform for rahkaran ERP system for providing integrated products that are produced by partner development companies.",
      "imgurl": "images/portfolio/systemgroup.jpg"
    },
    {
      "name": "Rahkaran",
      "link":"https://en.systemgroup.net/products/rahkaran-erp/",
      "description": " Rahkaran is a comprehensive and integrated ERP solution along with Business process management (BPM) Tools.",
      "imgurl": "images/portfolio/rahkaran.png"
    },
    {
      "name": "Ganjeh",
      "link":"http://viratech.ir/%DA%AF%D9%86%D8%AC%D9%87/",
      "description": "Document Management System.",
      "imgurl": "images/portfolio/ganjeh.jpg"
    }
  ],
  "testimonials": [
    {
      "description": "If you are seeking jewelry, you are jewelry If you are seeking bread, then you are bread. Only if you know the secret, you would know that You are what you are seeking.",
      "name": "Rumi"
    }
  ]
}

export default resumeData