import React, { Component } from 'react';

export default class Resume extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <section id="resume">

        <div className="row education">

          <div className="three columns header-col">
            <h1><span>Education</span></h1>
          </div>

          <div className="nine columns main-col">
            {
              resumeData.education && resumeData.education.map((item) => {
                return (
                  <div className="row item">
                    <div className="twelve columns">
                      <h3>{item.UniversityName}</h3>
                      <p className="info">
                        {item.specialization}
                        <span>&bull;</span> <em className="date">{item.MonthOfPassing} {item.YearOfPassing}</em></p>
                      <p>
                        {item.Achievements}
                      </p>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
        <div className="row work">
          <div className="three columns header-col">
            <h1><span>Work</span></h1>
          </div>

          <div className="nine columns main-col">
            {
              resumeData.work && resumeData.work.map((item) => {
                return (
                  <div className="row item">
                    <div className="twelve columns">
                      <h3>{item.CompanyName}</h3>
                      <p className="info">
                        {item.specialization}
                        <span>&bull;</span> <em className="date">{item.MonthOfLeaving} {item.YearOfLeaving}</em></p>
                      <p>
                        {item.Achievements}
                      </p>
                    </div>

                  </div>

                )
              })
            }
          </div>
        </div>


        <div className="row skill">

          <div className="three columns header-col">
            <h1><span>Skills</span></h1>
          </div>

          <div className="nine columns main-col">
            {resumeData.skills && resumeData.skills.items &&resumeData.skills.items.map((skillSet) => {
              return (
                <div>
                  <div className="bars">
                  <p className="info skill-title">
                    {skillSet.key}
                  </p>
                    <div className="skills">
                      {
                        skillSet && skillSet.items.map((item) => {
                          return (
                            resumeData.skills.type==='bar'?
                            <div className='skill-item'>
                              <span className={`bar-expand skill-animation-bar`} style={{ 'width': item.experience + '%' }}>
                              </span><em>{item.name}</em>
                            </div>
:
                            <div className='skill-item-2'>
                              {item.name}
                            </div>
                          )
                        })
                      }

                    </div>

                  </div>

                </div>
              )
            })}
          </div>

        </div>

      </section>
    );
  }
}